body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content-view-article {
  background-color: white;
  width: 30vw;
  margin-left: 35vw;
  margin-right: 35vw;
  margin-top: 10px;
  margin-bottom: 10px;
  height: calc(100vh - 20px);
}

.image-view-article {
  width: 100%;
  height: 35vh;
}

.container-view-article {
  padding: "10px";
  max-height: calc(100vh - 20px);
  overflow-x: auto;
}

.body-view-article {
  padding: 10px;
}
.body-view-article * {
  font-size: 14px;
  text-align: justify;
}
.body-view-article a {
  font-weight: bold;
  color: #000;
  text-decoration: underline;
}
.modal-close-view-article {
  position: absolute;
  margin: 10px;
  color: white;
  border-radius: 50%;
  background: red;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid red;
  font-weight: bold;
}

/* Styles for mobile devices (up to 767px) */
@media only screen and (max-width: 767px) {
  .content-view-article {
    width: 100vw;
    margin: 0;
    height: 100%;
  }
  .image-view-article {
    height: 40vh;
  }
}

/* Styles for tablets (768px to 991px) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .content-view-article {
    width: 40vw;
    margin-left: 30vw;
    margin-right: 30vw;
  }
  .image-view-article {
    height: 40vh;
  }
}

/* Styles for laptops (992px to 1199px) */
@media only screen and (min-width: 992px){
  .content-view-article {
    width: 30vw;
    margin-left: 35vw;
    margin-right: 35vw;
  }
  .image-view-article {
    height: 40vh;
  }
}
